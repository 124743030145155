import React from "react";
import {Spinner, titleCase, formatValue, KeyValueTable, FormattedMessage, EnhancedTable} from "@armus/armus-dashboard";
import {getColumn} from "../../../lib/columns";
import useDeciles from "../hook/useDeciles";
import {Box} from "@mui/material";

const TabDeciles = ({filters, activeCell}) => {
    const {measureProxy, columnId} = activeCell;
    const {
        data,
        decilesInfo,
        performanceRate,
        cmsBenchmark,
        isLoading,
        isError
    } = useDeciles(filters.year, measureProxy, columnId);

    if(isLoading) {
        return (
            <Spinner variant={"linear"} />
        );
    }
    if(isError) {
        return <FormattedMessage id="apiErrorMessage" />;
    }
    if(!data) {
        return <FormattedMessage id="insufficientData" />;
    }
    const column = getColumn(columnId);
    const ytdPerformanceRate = measureProxy.getPerformanceRate("ytd", false);


    const performance = {
        "Status": `${titleCase(data.status)} ${data.status === "historical" ? data.benchmarkYear : ""}`,
        [`${column.title} Performance Rate`]: formatValue("#,##0.##%", performanceRate),
        "Year To Date Performance Rate": formatValue("#,##0.##%", ytdPerformanceRate),
        "CMS Average Performance Rate": formatValue("#,##0.##%", cmsBenchmark)
    };

    return (
        <div>
            <KeyValueTable
                data={performance}
                showHeader={false}
            />
            {decilesInfo.length > 0 ? <React.Fragment>
                <EnhancedTable
                    rows={decilesInfo}
                    columns={[
                        {
                            id: "percentile",
                            label: "Percentiles",
                            props: {align: "center", width: "10"},
                            disablePadding: false,
                            isSortable: false,
                            Content: ({row, cell}) => {
                                return (
                                    <Box sx={{fontWeight: row.active ? "bold" : ""}}>
                                        {row[cell.id]}
                                    </Box>
                                );
                            }
                        },
                        {
                            id: "decile",
                            label: "Deciles",
                            props: {align: "center", width: "10"},
                            disablePadding: false,
                            isSortable: false,
                            Content: ({row, cell}) => {
                                return (
                                    <Box sx={{fontWeight: row.active ? "bold" : ""}}>
                                        {row[cell.id]}
                                    </Box>
                                );
                            }
                        },
                        {
                            id: "value",
                            label: "CMS Value",
                            props: {align: "center", width: "10"},
                            disablePadding: false,
                            isSortable: false,
                            Content: ({row, cell}) => {
                                return (
                                    <Box sx={{px: 2, fontWeight: row.active ? "bold" : ""}}>
                                        {row[cell.id]}%
                                    </Box>
                                );
                            }
                        },
                        {
                            id: "rangeLabel",
                            label: "Range",
                            props: {align: "left", width: "1000"},
                            disablePadding: false,
                            isSortable: false,
                            Content: ({row, cell}) => {
                                return (
                                    <Box sx={{fontWeight: row.active ? "bold" : ""}}>
                                        {row[cell.id]}&nbsp;
                                        {row.active && ` <- ${column.title} Performance Rate (${formatValue("#,##0.##%", performanceRate)})`}
                                    </Box>
                                );
                            }
                        }
                    ]}
                    dense={true}
                    showHeader={true}
                />
                <p style={{textAlign: "center"}}>
                    {measureProxy.isInverse() ?
                        <FormattedMessage id="decileInverseMessageOn" />
                        : <FormattedMessage id="decileInverseMessageOff" />
                    }
                </p>
            </React.Fragment> : "No percentile data available."
            }
        </div>
    );
};

export default TabDeciles;
