import React from "react";
import api from "../../../lib/api";
import { EnhancedTable, ControlResolver, Spinner, titleCase, FormattedMessage } from "@armus/armus-dashboard";
import FiltersStore from "../../../data/dashboard/FiltersStore";
import { Grid } from "@mui/material";
import LocationStore from "../../../data/LocationStore";
import moment from "moment-timezone";
import config from "../../../../config";

const performceItems = [
    {
        label: "All",
        value: ""
    },
    {
        label: "Met",
        value: "met"
    },
    {
        label: "Not Met",
        value: "notMet"
    },
    {
        label: "Exclusion",
        value: "exclusion"
    },
    {
        label: "Exception",
        value: "exception"
    },
    {
        label: "Eligible",
        value: "eligible"
    },
    {
        label: "Not Eligible",
        value: "notEligible"
    }
];

const TabRecords = (props) => {
    const {activeCell, isLoading, isError, data, setOffset, getPhysicianInfo, onFilterChange, recordFilters} = props;
    const {measureProxy} = activeCell;
    if(!data && isLoading) {
        return (
            <Spinner variant={"linear"} />
        );
    }
    if(isError) {
        return <FormattedMessage id="apiErrorMessage" />;
    }
    if(!data) {
        return <FormattedMessage id="insufficientData" />;
    }
    const headerCells = [
        {
            id: "eventDate",
            props: {align: "left", width: "20%"},
            disablePadding: false,
            isSortable: false,
            label: "Event Date",
            Content: ({row, cell}) => {
                return moment(row[cell.id]).utc().format(config.ui.dateFormat);
            }
        },
        {
            id: "site",
            props: {align: "left"},
            disablePadding: false,
            isSortable: false,
            label: "Site",
            Content: ({row, cell}) => {
                return row[cell.id];
            }
        },
        {
            id: "mrn",
            props: {align: "left"},
            disablePadding: false,
            isSortable: false,
            label: "Medical Record Number",
            Content: ({row, cell}) => {
                return row[cell.id];
            }
        },
        {
            id: "physician",
            props: {align: "left"},
            disablePadding: false,
            isSortable: false,
            label: "Provider",
            Content: ({row, cell}) => {
                return getPhysicianInfo(row[cell.id]).label || "-";
            }
        }
    ];
    const componentFilters = [];
    const components = measureProxy.getComponents();
    if(components.length > 0) {
        components.forEach((mp, index) => {
            headerCells.push({
                id: mp.id,
                props: { align: "left" },
                disablePadding: false,
                isSortable: false,
                label: mp.title(),
                Content: ({row}) => {
                    return titleCase(row["performances"][index]);
                }
            });
            componentFilters.push({
                id: "performance_" + mp.id,
                label: mp.title(),
                items: performceItems,
                type: "select",
                value: recordFilters["performance_" + mp.id] || ""
            });
        });
    }
    else {
        headerCells.push({
            id: "performances",
            props: {align: "left"},
            disablePadding: false,
            isSortable: false,
            label: "Status",
            Content: ({row, cell}) => {
                return titleCase(row[cell.id][0]);
            }
        });
        componentFilters.push({
            id: "performance",
            label: "Status",
            type: "select",
            items: performceItems,
            value: recordFilters.performance || ""
        });
    }

    const filters = [
        {
            id: "mrn",
            label: "Medical Record Number",
            type: "text",
            value: recordFilters.mrn || ""
        },
        {
            id: "siteFilter",
            label: "Site Search",
            type: "text",
            value: recordFilters.siteFilter || ""
        },
        ...componentFilters
    ];

    const rows = (data || {}).values || [];
    const paging = (data || {}).paging || {};
    return (
        <div>
            <Grid
                container
                alignItems="center"
                justify="flex-start"
                spacing={2}
            >
                {filters.map((it) => {
                    return (
                        <Grid key={it.id} item xs={2}>
                            <ControlResolver
                                descriptor={{
                                    id: it.id,
                                    label: it.label,
                                    type: it.type,
                                    config: {
                                        dense: true,
                                        options: it.items
                                    }
                                }}
                                value={{[it.id]: it.value}}
                                onChange={(e,v) => {onFilterChange(it.id, v);}}
                                onKeyDown={(e) => e.stopPropagation()}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <EnhancedTable
                isLoading={isLoading}
                rows={rows}
                columns={headerCells}
                dense={true}
                hover={false}
                pagination={paging.total !== undefined}

                rowsPerPageOptions={[paging.limit]}
                total={paging.total}
                rowsPerPage={paging.limit}
                page={paging.offset/paging.limit}
                onChangePage={(e, page) => setOffset(page * paging.limit)}
                onChangeRowsPerPage={()=>{}}
            />
        </div>
    );
};

export default ({activeCell}) => {
    const filters = FiltersStore.getAllFiltersValue(true);
    const measureProxy = activeCell.measureProxy;
    const measureId = measureProxy.id;
    const columnId = activeCell.columnId;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [data, setData] = React.useState(undefined);
    const [offset, setOffset] = React.useState(0);
    const [recordFilters, setRecordFilters] = React.useState({});

    React.useEffect(() => {
        setOffset(0);
        //eslint-disable-next-line
    }, [
        filters.year,
        filters.physicians,
        filters.siteFilter
    ]);
    React.useEffect(() => {
        setIsLoading(true);
        const params = LocationStore.getParams();
        const components = measureProxy.getComponents();
        filters.performances = components.length > 0 ?
            components.map(it => recordFilters["performance_" + it.id] || "")
            : [recordFilters.performance];
        filters.mrn = recordFilters.mrn;
        filters.siteFilter = recordFilters.siteFilter;
        api.loadDashboardMeasureYearDetailData(params.year, params.orgKey, params.implKey, measureId, columnId, filters, 10, offset)
            .then((res)=> {
                setIsLoading(false);
                setIsError(false);
                setData(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
        //eslint-disable-next-line
    }, [
        measureId,
        columnId,
        filters.year,
        filters.physicians,
        filters.siteFilter,
        recordFilters,
        offset
    ]);
    return (
        <TabRecords
            activeCell={activeCell}
            isLoading={isLoading}
            isError={isError}
            data={data}
            setOffset={setOffset}
            recordFilters={recordFilters}
            getPhysicianInfo={(value) => FiltersStore.getFilterItemInfoByValue("physicians", value) || {}}
            onFilterChange={(key, value) => {
                setOffset(0);
                setRecordFilters({...recordFilters, [key]: value || ""});
            }}
        />
    );
};
