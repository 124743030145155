import React from "react";
import PropTypes from "prop-types";
import { darken } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IndividualIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/People';
import { FormattedMessage, formatMessage, Spinner, Paper, Main, AlertBar } from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import NPIStore from "../../../data/submit/NPIStore";
import UserStore from "../../../data/UserStore";
import { buildUrl } from "../../../../routes";
import history from "../../../lib/history";
import moment from "moment-timezone";
import ErrorPage from "../../ErrorPage";
import HomeTable from "./HomeTable";
import config from "../../../../config";
import HasDRCF from "../../components/HasDRCF";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll"
    },
    paper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    tabsContainer: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        flexGrow: 1
    },
    tabs: {
        borderBottom: "1px solid " + darken(theme.palette.background.default, 0.25),
        boxShadow: "0 2px 2px rgba(0,0,0,.25)",
        "& .MuiTabs-flexContainer": {
            borderBottom: "3px solid " + theme.palette.background.default

        },
        "& .MuiTab-root.Mui-selected": {
            fontWeight: "bold"
        },
        "& .MuiTabs-indicator": {
            height: 3
        }
    }
}));

export const homeBreadcrumb = (year, org = {}, impl = {}) => ({
    label: formatMessage({
        id: "home.breadcrumb",
        defaultMessage: "{year} {orgName} {implName}"},
        {orgName: org.name, implName: impl.name, year}
    ),
    url: buildUrl("MIPS", {year, orgKey: org.key, implKey: impl.key})
});

const Home = ({
    isValidURL,
    year,
    organization,
    implementation,
    npis,
    conflicts,
    yearInfo,
    setView,
    isLoading,
    onEditClick,
    onReceiptClick
}) => {
    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    if(!isValidURL) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }

    const breadcrumb = homeBreadcrumb(year, organization, implementation);
    let body;
    if(npis.length === 0 && isLoading) {
        body = (
            <Spinner/>
        );
    }
    else {
        const messages = [];
        let canSubmit = moment(yearInfo.submissionOpen) < moment() // start date less than now
                        && moment(yearInfo.submissionClose) > moment(); // end date greater than now
        const handleTabChange = (event, newValue) => setValue(newValue);
        let welcomeMessage = null;
        if(canSubmit) {
            welcomeMessage = (
                <React.Fragment>
                    <FormattedMessage
                        id="home.welcome"
                        defaultMessage="Please use the Individual or Grouped Tabs below to select which NPI/TIN you would like to submit for {year}."
                        values={{year: yearInfo.year}}
                    />
                    <br/>
                    <FormattedMessage
                        id="home.submissionWindowOpen"
                        defaultMessage="You have {days} days remaining before the submission window is closed on {endDate}."
                        values={{
                            days: moment(yearInfo.submissionClose).diff({hours: 0}, 'days'),
                            endDate: moment(yearInfo.submissionClose).format(config.ui.dateTimeFormat)
                        }}
                    />
                </React.Fragment>
            );
            if(conflicts.length > 0) {
                messages.push(
                    <AlertBar
                        key={"conflicts"}
                        size={"small"}
                        severity={"error"}
                        label={(
                            <FormattedMessage
                                id="home.conflict"
                                defaultMessage="There are TIN/NPI pair conflicts."
                            />
                        )}
                    >
                        <span>
                            <FormattedMessage
                                id="home.conflict.details"
                                defaultMessage="The following TIN/NPI pairs could not be processed due to a conflict."
                            />
                            <ul>
                                {conflicts.map((conflict,index) => (
                                    <li key={index}>
                                        <strong>TIN:</strong> {conflict.tin}, <strong>NPI:</strong> {conflict.npi}
                                    </li>
                                ))}
                            </ul>
                        </span>
                    </AlertBar>
                );
            }
        }
        else if(moment(yearInfo.submissionOpen) > moment()) {
            // days until submission starts
            welcomeMessage = (
                <FormattedMessage
                    id="home.submissionWindowBeginsIn"
                    defaultMessage="You can prepare your submission draft now by clicking Edit Submission."
                    values={{
                        year,
                        days: moment(yearInfo.submissionOpen).diff({hours: 0}, 'days'),
                        startDate: moment(yearInfo.submissionOpen).format(config.ui.dateTimeFormat),
                        endDate: moment(yearInfo.submissionClose).format(config.ui.dateTimeFormat)
                    }}
                />
            );
        }
        else if(moment(yearInfo.submissionClose) < moment()) {
            // days since submission ended
            welcomeMessage = (
                <FormattedMessage
                    id="home.submissionWindowClosed"
                    defaultMessage="The submission window for {year} ended {days} days ago. You can review your final submissions in the table below."
                    values={{
                        year,
                        days: moment(yearInfo.submissionClose).diff({hours: 0}, 'days'),
                        startDate: moment(yearInfo.submissionOpen).format(config.ui.dateTimeFormat),
                        endDate: moment(yearInfo.submissionClose).format(config.ui.dateTimeFormat)
                    }}
                />
            );
        }

        /*
        messages.push(
            <AlertBar
                key={"whereAreMyProviders"}
                severity={"info"}
                size={"small"}
                label={<FormattedMessage
                    id="home.whereAreMyProviders"
                    defaultMessage="Where are my providers?"
                />}
            >
                <FormattedMessage
                    id="home.whereAreMyProvidersContent"
                    defaultMessage="You will see only those providers included in the MIPS Submitting Entities created via your Data Release Consent Forms for this site."
                    values={{year}}
                />
            </AlertBar>
        );
        */

        body = (
            <React.Fragment>
                <Typography component="div" px={3} className={classes.subtitle} variant="subtitle1">
                    {welcomeMessage}
                </Typography>
                <div className={classes.tabsContainer}>
                    <Tabs
                        className={classes.tabs}
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                    >
                        <Tab label={<React.Fragment>
                            <IndividualIcon /> <FormattedMessage id={"home.individual.tab.label"} defaultMessage={"Individual"} />
                        </React.Fragment>} />
                        <Tab label={<React.Fragment>
                            <GroupIcon /> <FormattedMessage id={"home.group.tab.label"} defaultMessage={"Group"} />
                        </React.Fragment>} />
                    </Tabs>
                    {messages.length > 0 && (
                        <Box p={2} pt={0} pb={1}>
                            {messages}
                        </Box>
                    )}
                    <HomeTable
                        isLoading={isLoading}
                        npis={npis}
                        onEditClick={onEditClick}
                        onReceiptClick={onReceiptClick}
                        type={value === 0 ? "individual" : "group"}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" p={3}>
                        <FormattedMessage
                            id="home.title"
                            defaultMessage={"{year} {orgName} {implName}"}
                            values={{orgName: organization.name, implName: implementation.name, year}}
                        />
                    </Typography>
                    {body}
                </Paper>
            </Box>
        </Main>
    );
};

Home.propTypes = {
    isValidURL: PropTypes.bool.isRequired,
    year: PropTypes.string,
    organization: PropTypes.object,
    implementation: PropTypes.object,
    npis: PropTypes.array,
    conflicts: PropTypes.array,
    yearInfo: PropTypes.object,
    isLoading: PropTypes.bool,
    setView: PropTypes.func,
    onEditClick: PropTypes.func,
    onReceiptClick: PropTypes.func
};

const WrappedHome = (props) => {
    return (
        <HasDRCF year={props.year} orgKey={props.organization.key} implKey={props.implementation.key}>
            <Home {...props} />
        </HasDRCF>
    );
};

export default Container(
    WrappedHome,
    () => [
        UserStore,
        NPIStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const isValidURL = !!org && !!impl;
        return {
            ...props,
            isValidURL,
            year,
            organization: org || {},
            implementation: impl || {},
            npis: NPIStore.getNPIs(),
            yearInfo: NPIStore.getYear(),
            isLoading: NPIStore.isLoading(),
            conflicts: NPIStore.getConflicts(),
            onEditClick: (id, type) => {
                const url = buildUrl("DRAFT", {year: year, orgKey: orgKey, implKey: implKey, npiId: id, type});
                history.push(url);
            },
            onReceiptClick: (npiId, receiptId, type) => {
                const url = buildUrl("RECEIPT", {year: year, orgKey: orgKey, implKey: implKey, npiId: npiId, receiptId: receiptId, type});
                history.push(url);
            }
        };
    }
);
