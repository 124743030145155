import React from "react";
import Chart from "../../components/Chart";
import {getColumn} from "../../../lib/columns";
import api from "../../../lib/api";
import {Spinner, FormattedMessage} from "@armus/armus-dashboard";
import FiltersStore from "../../../data/dashboard/FiltersStore";
import LocationStore from "../../../data/LocationStore";

const CaseVolumeTab = ({isLoading, isError, data}) => {
    if(isLoading) {
        return (
            <Spinner variant={"linear"} />
        );
    }
    if(isError) {
        return <FormattedMessage id="apiErrorMessage" />;
    }
    if(!data) {
        return <FormattedMessage id="insufficientData" />;
    }
    const chartData = [];
    Object.keys(data).forEach((month) => {
        chartData.push({
            label: getColumn(month).title,
            value: data[month] || 0
        });
    });

    return (
        <Chart title={"Total Case Volume"} type={"line"} data={chartData} height={300} min={0} />
    );
};

export default ({filters}) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [data, setData] = React.useState(undefined);
    React.useEffect(() => {
        if(!filters.year) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        const params = LocationStore.getParams();
        api.loadDashboardCaseVolumeData(params.year, params.orgKey, params.implKey, FiltersStore.getAllFiltersValue(true))
            .then((res)=> {
                setIsLoading(false);
                setData(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
    //eslint-disable-next-line
    }, [filters.year, filters.physicians, filters.sites]);

    return (
        <CaseVolumeTab isLoading={isLoading} isError={isError} data={data} />
    );
};
