import React from "react";
import api from "../../../lib/api";
import {formatValue} from "@armus/armus-dashboard";
import {getBenchmarkColor} from "../../../lib/color";

/* data shape from CMS
    {
        benchmarkYear: 2020
        deciles: [
          100,
          1.9,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ],
        measureId: "IGR10"
        performanceYear: 2020
        status: "currentInsufficientData" or "historical"
        submissionMethod: "registry"
    }

    // NEW data shape from CMS 2024 format
    {
        "benchmarkYear": 2022,
        "performanceYear": 2024,
        "metricType": "singlePerformanceRate",
        "status": "historical",
        "isInverse": true,
        "isToppedOut": false,
        "isToppedOutByProgram": false,
        "isHighPriority": true,
        "percentiles": {
            "1": 0,
            "10": 0,
            "20": 0,
            "30": 0,
            "40": 0,
            "50": 0,
            "60": 0,
            "70": 0,
            "80": 0,
            "90": 0.09,
            "99": 3.03
        },
        "submissionMethod": "registry",
        "measureId": "439"
    },
    {
        "benchmarkYear": 2022,
        "performanceYear": 2024,
        "metricType": "singlePerformanceRate",
        "status": "historical",
        "isInverse": false,
        "isToppedOut": true,
        "isToppedOutByProgram": true,
        "isHighPriority": true,
        "percentiles": {
            "1": 100,
            "10": 100,
            "20": 100,
            "30": 100,
            "40": 100,
            "50": 100,
            "60": 100,
            "70": 100,
            "80": 100,
            "90": 100,
            "99": 100
        },
        "submissionMethod": "claims",
        "measureId": "320"
    },
    */

const useDeciles = ( year, measureProxy, columnId) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [data, setData] = React.useState(null);
    const performanceRate = measureProxy.getPerformanceRate(columnId, false);
    const isInverse = measureProxy.isInverse();
    const cmsBenchmark = measureProxy.getBenchmark();

    React.useEffect(() => {
        setIsLoading(true);
        api.loadDashboardDecileData(
            year,
            measureProxy.meta.measureId
        )
            .then((res)=> {
                setData(res.data);
                setIsLoading(false);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
    }, [year, measureProxy.meta.measureId]);

    if(isLoading || isError || !data) {
        return {
            data,
            decilesInfo: null,
            currentDecile: null,
            performanceRate,
            cmsBenchmark,
            isLoading,
            isError
        };
    }


    let percentileKeys = Object.keys(data.percentiles || {});
    if(isInverse) {
       percentileKeys.reverse();
    }

    const decilesInfo = percentileKeys.map((key, index) => {
        const value = (data.percentiles || {})[key];
        const c = getBenchmarkColor(value, cmsBenchmark, measureProxy.isInverse());
        let rangeLabel = `${formatValue("#,##0.##%", value)}`;
        const decile = index+1 <= 10 ? index + 1 : '-';
        if(index === 10) {
            rangeLabel = '-';
        }
        return {
            percentile: key,
            decile: decile,
            value: value,
            rangeLabel: rangeLabel,
            range: [value, value],
            color: c,
            active: false
        };
    });

    let foundRange = false;
    let currentDecile = null;
    for(let i = 0; i < decilesInfo.length; i++) {
        const cp = decilesInfo[i];
        const hp = decilesInfo[i+1] || null;
        if(hp != null) {
            let nextRange = hp.value - ( cp.decile === 10 ? 0 : isInverse ? -.01 : 0.01);
            if(nextRange < 0) {
                nextRange = 0;
            }
            cp.rangeLabel = `${formatValue("#,##0.##%", cp.value)} - ${formatValue("#,##0.##%", (nextRange))}`;
            cp.range = [cp.value, nextRange];
        }
        if(cp.decile === 10) {
            if(cp.value === 100 || cp.value === 0) {
                cp.rangeLabel = `${formatValue("#,##0.##%", cp.value)}`;
                cp.range = [cp.value, cp.value];
            }
        }
        else if(hp != null && cp.value === hp.value && decilesInfo.length-1 !== i) {
            cp.rangeLabel = "-";
            cp.range = [null, null];
        }
        if(isInverse) {
            if(
                cp.range[0] !== null && cp.range[1] !== null &&
                cp.range[0] >= performanceRate && performanceRate >= cp.range[1] && !foundRange
            ) {
                cp.active = true;
                foundRange = true;
                currentDecile = cp;
            }
        }
        else {
            if(
                cp.range[0] !== null && cp.range[1] !== null &&
                cp.range[0] <= performanceRate && performanceRate <= cp.range[1] && !foundRange
            ) {
                cp.active = true;
                foundRange = true;
                currentDecile = cp;
            }
        }
    }

    return {
        data,
        decilesInfo,
        currentDecile,
        performanceRate,
        cmsBenchmark,
        isLoading,
        isError
    };
};


export default useDeciles;
