import React from 'react';
import Container from "../../../data/Container";
import Dispatcher from "../../../data/Dispatcher";
import FiltersActions from "../../../data/dashboard/FiltersActions";
import FiltersStore from "../../../data/dashboard/FiltersStore";
import { ControlResolver, Spinner, FormattedMessage } from "@armus/armus-dashboard";
import { Grid } from "@mui/material";

const Filters = ({
                     isLoaded,
                     isError,
                     filters,
                     onChange
                 }) => {
    if(isError) {
        return (
            <FormattedMessage id="apiErrorMessage" data={["filter"]} />
        );
    }
    if(!isLoaded) {
        return (
            <Spinner variant={"linear"} />
        );
    }
    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            spacing={2}
        >
            {filters.map((it) => {
                return (
                <Grid key={it.key} item xs={it.spacing}>
                    <ControlResolver
                        descriptor={{
                            id: it.key,
                            label: it.label,
                            type: it.displayType,
                            description: it.description,
                            config: {
                                dense: true,
                                groupBy: false,
                                multivalued: it.multiplicity === "multiple"
                            }
                        }}
                        options={it.items}
                        data={it.items}
                        value={{[it.key]: it.value}}
                        onChange={(e,v) => {onChange(it.key, v);}}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </Grid>
            );})}
        </Grid>
    );
};

export default Container(
    Filters,
    () => [
        FiltersStore
    ],
    () => {
        return {
            isLoaded: FiltersStore.isLoaded(),
            isError: FiltersStore.isError(),
            filters: FiltersStore.getAllFilters(),
            onChange: (filterKey, value) => {
                Dispatcher.dispatch({
                    type: FiltersActions.change,
                    filterKey,
                    value
                });
            }
        };
    }
);
