import React from "react";
import Chart from "../../components/Chart";
import { Grid } from "@mui/material";
import { KeyValueTable, formatValue, FormattedMessage } from "@armus/armus-dashboard";
import { getColumns } from "../../../lib/columns";

const MeasuresGraphTab = (props) => {
    const {activeCell} = props;
    const columns = getColumns("months");
    const {measureProxy} = activeCell || {};

    if(!measureProxy) {
        return <FormattedMessage id="insufficientData" />;
    }
    const tableData = [];
    const chartData = [];
    const cmsBenchmark = measureProxy.getBenchmark();
    const colors = [];
    columns.forEach((col) => {
        const cell = measureProxy.getColumn(col.key);
        const color = measureProxy.getColumnColor(col.key);
        const smartColor = measureProxy.getColumnColor(col.key);
        colors.push(smartColor.backgroundColor);
        chartData.push({
            label: col.shortTitle,
            value: (( cell.performanceRate) || 0)/100
        });
        const row = {
            Month: col.title,
            "Eligible": formatValue(null, cell.eligible),
            "Exclusion": formatValue(null, cell.exclusion),
            "Exception": formatValue(null, cell.exception),
            "Performance Met": formatValue(null, cell.met),
            "Performance Not Met": formatValue(null, cell.notMet),
            "Data Completeness Rate": formatValue("percent", cell.reportingRate/100),
            "Performance Rate": (
                <div style={{textAlign: "right"}}>
                    {formatValue("percent", cell.performanceRate /100)}
                    <div style={{
                        backgroundColor: color.backgroundColor,
                        width: 16,
                        height: 16,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: 8
                    }}
                    />
                </div>
            )
        };
        tableData.push(row);
    });

    const markers = [];
    if(!!cmsBenchmark) {
        markers.push({
            label: "CMS Average Performance Rate",
            value: ((cmsBenchmark) || 0)/100,
            visible: true
        });
    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Chart
                        title={"Performance Rate"}
                        type={"bar"}
                        data={chartData}
                        format={"percent"}
                        config={{
                            colorScheme: colors,
                            markers: markers,
                            legend: false,
                            subLegend: false,
                            yLabel: "",
                            xLabel: "",
                            min: 0,
                            max: 1
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyValueTable
                        data={tableData}
                        dataArray={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MeasuresGraphTab;
