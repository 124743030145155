export const colorBuckets = [
    {
        backgroundColor: "#d73027",
        color: "#ffffff",
        calc: (v) => v <= -.5,
        title: ">=50%",
        subTitle: "worse by"
    }, // >=50% Selected cell is worse by >=50% compared to benchmark
    {
        backgroundColor: "#f46d43",
        color: "#ffffff",
        calc: (v) => v <= -.25 && v > -.5,
        title: "25%-50%",
        subTitle: "worse by"
    }, // 25%-50%
    {
        backgroundColor: "#fdae61",
        color: "#ffffff",
        calc: (v) => v <= -.1 && v > -.25,
        title: "10%-25%",
        subTitle: "worse by"
    }, // 10%-25%
    {
        backgroundColor: "#fee08b",
        color: "#333333",
        calc: (v) => v <= -.01 && v > -.1,
        title: "1%-10%",
        subTitle: "worse by"
    }, // 1%-10%
    {
        backgroundColor: "#ffffbf",
        color: "#333333",
        calc: (v) => v >= -0.1 && v <= .01,
        title: "0%-1%",
        subTitle: "within"
    }, // 0%-1% Selected cell matches benchmark.
    {
        backgroundColor: "#d9ef8b",
        color: "#333333",
        calc: (v) => v >= .01 && v < .1,
        title: "1%-10% ",
        subTitle: "better by"
    }, // 1%-10%
    {
        backgroundColor: "#a6d96a",
        color: "#ffffff",
        calc: (v) => v >= .1 && v < .25,
        title: "10%-25%",
        subTitle: "better by"
    }, // 10%-25%
    {
        backgroundColor: "#66bd63",
        color: "#ffffff",
        calc: (v) => v >= .25 && v < .5,
        title: "25%-50%",
        subTitle: "better by"
    }, // 25%-50%
    {
        backgroundColor: "#1a9850",
        color: "#ffffff",
        calc: (v) => v >= .5,
        title: ">=50%",
        subTitle: "better by"
    } // >=50% Selected cell is better by >=50% compared to benchmark
];

export const getBenchmarkColor = (value, benchmark, inverse = false) => {
    if(benchmark === null) {
        return {
            backgroundColor: "#88888866",
            color: "#000000",
            title: "not enough data",
            subTitle: "",
            value: value
        };
    }
    let v = (( value - benchmark ) / benchmark);
    if(isNaN(v)) {
        return {
            backgroundColor: "#88888888",
            color: "#000000",
            title: "not enough data",
            subTitle: "",
            value: v
        };
    }
    for(let i in colorBuckets) {
        if(colorBuckets[i].calc(v)) {
            if (inverse) {
                i = colorBuckets.length-1 - i;
            }
            return {
                ...colorBuckets[i],
                value: v
            };
        }
    }
    console.log(`color.getBenchmarkColor() Color "${v}" is out of range.`);
    return {
        backgroundColor: "#cccccc",
        color: "#000000",
        title: "out of range",
        subTitle: "",
        value: v
    };
};
