import React from "react";
import {buildPropTypesFromObject, buildPropTypesWithDescriptor} from "../../lib/propTypeHelpers";
import {
    TextField,
    Chip,
    Autocomplete,
    Box
} from "@mui/material";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        position: "relative",
        marginTop: 1,
        marginBottom: .5,
    },
    count: {
        flex: "flex-grow",
        fontSize: 12,
        textAlign: "right",
        flexGrow: 4,
        opacity: .7
    }
}));

const scrollElementIntoView = (query, options = {block: "center", behavior: "auto"}) => {
    setTimeout(() => {
        const element = document.querySelector(query);
        element?.scrollIntoView(options);
    }, 1);
};

const buildOptionKey = (key = "", option = {label: "", value: ""}, groupByKey = "") => {
    return key + "-" + option.value.toString() + "-" + (option[groupByKey] || "").toString();
};

const Typeahead = ({isLoading, required, options, disabled, descriptor, data, value, label, onClick, onChange, onBlur, onFocus, onKeyDown, chipProps, ...extraProps}) => {
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);
    label = descriptor.label || label;
    let mergedOptions = [];
    if(data || options || cfg.options) {
        mergedOptions = (data || []).concat(options || []);
        (cfg.options || []).forEach(a => {
            if (Array.isArray(data) && data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }
    let groupBy;
    if(Array.isArray((mergedOptions[0] || {}).value)) {
        // grouped data structure
        const newOptions = [];
        mergedOptions.forEach(o => o.value.forEach(v => newOptions.push({...v, group: o.label, groupId: o.id})));
        mergedOptions = newOptions;
        groupBy = (option) => option.group;
    }
    else if(!!cfg.groupBy) {
        switch (cfg.groupBy) {
            case "label":
                mergedOptions = mergedOptions.map((option) => {
                    const group = (option.label[0] || "").toUpperCase();
                    return {
                        group: /[0-9]/.test(group) ? '0-9' : group,
                        groupId: option.id,
                        ...option,
                    };
                });
                mergedOptions.sort((a, b) => -b.group.localeCompare(a.group));
                groupBy = (option) => option.group;
                break;
            case "value":
                mergedOptions.sort((a, b) => -b.value.toString().localeCompare(a.value.toString()));
                groupBy = (option) => option.value;
                break;
            default:
                mergedOptions.sort((a, b) => -(b[cfg.groupBy] || "").toString().localeCompare((a[cfg.groupBy] || "").toString()));
                groupBy = (option) => option[cfg.groupBy] || "";
        }
    }

    const isMultivalued = cfg.multivalued || extraProps.multiple || false;
    const rawValue = value;
    if(isMultivalued) {
        value = !Array.isArray(value) ? [] : value.map((v)=> mergedOptions.find((o) => o.value === v));
    }
    else {
        if(rawValue === null || rawValue === undefined) {
            value = null;
        }
        else {
            value = (mergedOptions.find((o) => o.value === value));
            if (value === undefined && rawValue !== "") {
                // value was not found add it
                value = { label: rawValue, value: rawValue };
                mergedOptions = [value].concat(mergedOptions);
            }
        }
    }

    const size = extraProps.size || (cfg.dense ? "small" : "large");

    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        // mergedOptions = [];
        disabled = true;
    }

    return (
        <Autocomplete
            onOpen={() => {
                if(isMultivalued && value?.length > 1) {
                    const optionToScrollTo = buildOptionKey(descriptor.id, value[value.length-1]);
                    const query = `[data-name="${optionToScrollTo}"]`;
                    scrollElementIntoView(query);
                }
            }}
            sx={classes.root}
            id={descriptor.id}
            name={descriptor.id}
            title={descriptor.description}
            loading={isLoading}
            autoHighlight={true}
            disabled={disabled}
            required={required}
            key={descriptor.id+(isMultivalued ? "multi" : "single")}
            multiple={isMultivalued}
            options={mergedOptions}
            disableCloseOnSelect={isMultivalued}
            getOptionLabel={(option) => option.label || ""}
            getOptionDisabled={(option) => option.disabled}
            groupBy={groupBy}
            value={value || " "}
            disableClearable={!cfg.allowClear}
            renderOption={(props, option) => (
                <Box {...props} data-name={buildOptionKey(descriptor.id, option, groupBy)}>
                    {option.label}
                    {(cfg.showCounts) && <Box sx={classes.count}>{option.count || (cfg.hideZeroCounts ?  "" : "0")}</Box>}
                </Box>
            )}
            renderInput={(inputProps) => (
                <TextField
                    disabled={disabled}
                    required={required}
                    variant="outlined"
                    label={label}
                    placeholder={cfg.placeholder}
                    {...inputProps}
                    size={size}
                    error={extraProps.error}
                    InputProps={isLoading ? {
                        ...inputProps.InputProps,
                        endAdornment: (
                            <Spinner size={18} overlay={true} />
                        ),
                    } : inputProps.InputProps}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        key={index}
                        label={(option ? option.label : null) || "Unknown"}
                        color={"default"}
                        {...getTagProps({ index })}
                        size={size}
                        {...chipProps}
                    />
                ))
            }
            size={size}
            onClick={(e) => onClick && onClick(e, getValue(value, isMultivalued), descriptor)}
            onChange={(e, v) => {
                onChange && onChange(e, getValue(v, isMultivalued), descriptor);
                if(isMultivalued && value?.length > 1) {
                    const optionToScrollTo = buildOptionKey(descriptor.id, v[v.length-1], groupBy);
                    const query = `[data-name="${optionToScrollTo}"]`;
                    scrollElementIntoView(query);
                }
            }}
            onBlur={(e) => onBlur && onBlur(e, getValue(value, isMultivalued), descriptor)}
            onFocus={(e) => onFocus && onFocus(e, getValue(value, isMultivalued), descriptor)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e, getValue(value, isMultivalued), descriptor)}
            {...extraProps}
        />
    );
};

const getValue = (value, multivalued = false, valueKey) => {
    return multivalued ? value.map(it => returnNullOrValue(it, valueKey)) : returnNullOrValue(value, valueKey);
};

const returnNullOrValue = (it, valueKey = "value") => {
    if(it === null || it === undefined) {
        return null;
    }
    if(typeof it === "object") {
        return it[valueKey];
    }
};

Typeahead.defaultProps = {
    value: null,
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            placeholder: "Search",
            multivalued: false,
            options: [],
            groupBy: "label",
            dense: true,
            showCounts: false,
            hideZeroCounts: false,
            allowClear: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Typeahead.defaultProps.descriptor.config,
    ...config
});

Typeahead.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));

export default Typeahead;
