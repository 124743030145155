import React from "react";
import {EnhancedTable, KeyValueTable, formatValue, FormattedMessage} from "@armus/armus-dashboard";
import {Equation} from 'react-equation';
import MeasuresMetaStore from "../../../data/dashboard/MeasuresMetaStore";
import MeasureProxy from "../../../data/dashboard/utils/MeasureProxy";

const CalculationsTab = (props) => {
    const {activeCell} = props;
    const {measureProxy, columnId, x} = activeCell || {};
    const cell = measureProxy.getColumn(columnId);
    const color = measureProxy.getColumnColor(columnId);
    const cellContent = (props) => props.row.getColumn(columnId)[props.cell.id];
    if(measureProxy && x > 0) {
        if(!cell) {
            return <FormattedMessage id="insufficientData" />;
        }
        const headerCells = [ // Eligible Population, Exclusion, Exception, Performance Not Met, Performance Met
            {
                id: "title",
                props: {align: "left"},
                disablePadding: false,
                isSortable: false,
                label: "Measure",
                Content: (props) => {
                    const mp = props.row;
                    if(mp.meta.title === "overall") {
                        return (
                            <React.Fragment>
                                <strong>{mp.title()}</strong> (Total)
                            </React.Fragment>
                        );
                    }
                    return mp.title();
                }
            },
            {
                id: "eligible",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Eligible Population",
                Content: cellContent
            },
            {
                id: "exclusion",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Exclusion",
                Content: cellContent
            },
            {
                id: "exception",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Exception",
                Content: cellContent
            },
            {
                id: "notMet",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Performance Not Met",
                Content: cellContent
            },
            {
                id: "met",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Performance Met",
                Content: cellContent
            },
            {
                id: "reportingRate",
                props: {
                    align: "center",
                    style: {
                        backgroundColor: "rgba(127,127,127,.1)",
                        fontWeight: "bold"
                    }
                },
                disablePadding: false,
                isSortable: false,
                label: "Reporting Rate",
                Content: (props) => formatValue("percent", cellContent(props) / 100, "Insufficient Data")
            },
            {
                id: "performanceRate",
                props: {
                    align: "center",
                    style: {
                        backgroundColor: "rgba(127,127,127,.1)",
                        fontWeight: "bold"
                    }
                },
                disablePadding: false,
                isSortable: false,
                label: "Performance Rate",
                Content: (props) => {
                    const color = props.row.getColumnColor(columnId);
                    return (
                        <div style={{
                            display: "inline-block",
                            backgroundColor: color.backgroundColor,
                            color: color.color,
                            fontWeight: "bold",
                            width: "100%",
                            padding: "5px",
                            marginLeft: 10,
                            position: "relative"
                        }}>{formatValue("percent", cellContent(props) / 100, "Insufficient Data")}</div>
                    );
                }
            }
        ];
        const rows = measureProxy.isMultiComponent() ? [...measureProxy.getComponents()] : [measureProxy];
        rows.sort((a, b) =>
            a.meta.title === "overall" ? -1 : b.meta.title === "overall" ? -1 : 0
        );
        let prefix = "";
        const keys = ["Data Completeness Rate", "Performance Rate"];
        keys.push("Color Rating");

        const data = {
            "Data Completeness Rate": (
                <Equation
                    value={`(Performance Met + Exception + Performance Not Met ) / (Eligible Population) * 100 = Data Completeness Rate =
                                (${cell.met} + ${cell.exception} + ${cell.notMet} ) / ${cell.eligible} * 100 =
                                ${formatValue("percent", cell.reportingRate / 100, "Insufficient Data")}`.replaceAll(',', '')
                    }
                    style={{fontSize: "115%"}}
                />
            ),
            "Performance Rate": getPerformanceRateCalculation(measureProxy, columnId)
        };

        if(measureProxy.getBenchmark() !== null) {
            data["Color Rating"] = (
                <React.Fragment>
                    <Equation
                        value={`( ${prefix} Performance Rate - CMS Average Performance Rate) / ( CMS Average Performance Rate ) * 100 = Percentage Within CMS Average Performance Rate =
                                ( ${formatValue("percent", measureProxy.getPerformanceRate(columnId) / 100, "Insufficient Data")} - ${formatValue("percent", measureProxy.getBenchmark() / 100, "Insufficient Data")} ) / ( ${formatValue("percent", measureProxy.getBenchmark() / 100, "Insufficient Data")} ) * 100 =
                                ( ${formatValue("percent", color.value , "Insufficient Data")} )`.replaceAll(',', '')
                        }
                        style={{fontSize: "115%"}}
                    />
                    <div style={{
                        display: "inline-block",
                        backgroundColor: color.backgroundColor,
                        color: color.color,
                        fontWeight: "bold",
                        padding: "5px 10px",
                        top: ".53em",
                        marginLeft: 10,
                        position: "relative"
                    }}>
                        = {color.subTitle} {color.title}
                    </div>
                </React.Fragment>
            );
        }
        else {
            data["Color Rating"] = ( "No CMS Average Performance Rate data available for color rating." );
        }

        return (
            <React.Fragment>
                <EnhancedTable
                    rows={rows}
                    columns={headerCells}
                    dense={true}
                    hover={false}
                    pagination={false}
                />
                <KeyValueTable
                    keys={keys}
                    data={data}
                    showHeader={false}
                />
            </React.Fragment>
        );
    }
    return (
        <FormattedMessage id="insufficientData" />
    );
};

const getPerformanceRateCalculation = (measureProxy, columnId) => {
    const cell = measureProxy.getColumn(columnId);
    switch(measureProxy.getAlgorithm()) {
        case "simpleAverage":
            const overallMeasure = MeasuresMetaStore.getMeasureMetaById(measureProxy.overallMeasureId());
            const subMeasures = overallMeasure.subComponents.map(mId => new MeasureProxy(mId));
            return <Equation
                    value={`( ${subMeasures.map(m => m.meta.title).join(" + ")} ) / ${subMeasures.length} = Overall Simple Average =
                            ( ${subMeasures.map(m => formatValue("percent", m.getColumn(columnId).performanceRate/ 100, "Insufficient Data")).join(" + ")} ) / ${subMeasures.length} =
                            ${formatValue("percent", cell.performanceRate / 100, "Insufficient Data")}`.replaceAll(',', '')
                    }
                    style={{fontSize: "115%"}}
                />;
        default:
            return (
                <Equation
                    value={`Performance Met / ( Performance Met + Performance Not Met ) * 100 = Performance Rate =
                            ${cell.met} / (${cell.met} + ${cell.notMet}) * 100 =
                            ${formatValue("percent", cell.performanceRate / 100, "Insufficient Data")}`.replaceAll(',', '')
                    }
                    style={{fontSize: "115%"}}
                />
            );
    }


};

export default CalculationsTab;
