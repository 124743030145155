import React from "react";
import { makeStyles } from "@mui/styles";
import { AlertBar } from "@armus/armus-dashboard";
import MeasuresContainer from "./components/MeasuresContainer";
import Container from "../../../data/Container";
import MeasuresMetaStore from "../../../data/submit/MeasuresMetaStore";
import MeasuresDraftStore from "../../../data/submit/MeasuresDraftStore";
import MeasuresScoreStore from "../../../data/submit/MeasuresScoreStore";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import SummaryGauges from "./components/SummaryGauges";
import moment from "moment-timezone";
import config from "../../../../config";
import PerformanceYearStore from "../../../data/PerformanceYearStore";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginTop: -theme.spacing(1),
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        top: 0,
        left: "auto",
        right: 0,
        position: "sticky",
        zIndex: 3
    },
    messagesContainer: {
        marginTop: theme.spacing(3)
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    acknowledgedBlock: {
        // marginBottom: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginLeft: theme.spacing(-2)
    },
    scoreChip: {
        minWidth: 50,
        fontWeight: "bold",
        fontSize: 14
    },
    toolbarSection: {
        marginLeft: theme.spacing(1)
    },
    summeryContainer: {
        padding: theme.spacing(2)
    },
    submitContainer: {
        padding: theme.spacing(2),
        backgroundColor: "rgba(0, 0, 0, .01)",
        borderLeft: "solid 1px " + theme.palette.divider,
        display: "flex",
        alignItems: "center",
        textAlign: "center"
    }
}));

const ReviewAndSubmit = ({
    yearInfo,
    canSubmit,
    stickyRef,
    onMeasureChange,
    measureSetProxies,
    measureSetProxy,
    onSubmit
}) => {
    const [acknowledged, setAcknowledged] = React.useState(false);
    const [stickyOffsetHeight, setStickyOffsetHeight] = React.useState(0);
    React.useEffect(() => {
            setStickyOffsetHeight(stickyRef.current ? stickyRef.current.clientHeight : 0);},
        [stickyRef]
    );
    const classes = useStyles();
    const selectedMeasuresCount = measureSetProxy.getSelectedMeasuresCount();
    const messages = measureSetProxy.getMessages();

    return (
        <React.Fragment>
            {measureSetProxies.filter(it=> it.id !== "review").map((measureSetProxy) => {
                if( !measureSetProxy.isIncluded() ) {return null;}
                return (
                    <MeasuresContainer
                        key={measureSetProxy.id}
                        isReview={true}
                        stickyRef={stickyRef}
                        measureSetProxy={measureSetProxy}
                        onMeasureChange={onMeasureChange}
                    />
                );}
            )}
            <React.Fragment>
                <div className={classes.root} style={{top: stickyOffsetHeight}}>
                    <AppBar
                        color={"primary-alt"}
                        position="sticky"
                        elevation={1}
                    >
                            <Toolbar>
                                <Typography className={classes.title} variant="body1" component={"div"} noWrap>
                                    <Typography variant={"h5"} component={"h2"}>Submit</Typography>
                                    {selectedMeasuresCount} measures selected for submission.
                                </Typography>
                                <div className={classes.toolbarSection}>
                                    Preview Score Total:&nbsp;
                                    <Chip color="default"
                                        className={classes.scoreChip}
                                        label={
                                            <React.Fragment>
                                                <strong>{measureSetProxy.getScore()}</strong> / {measureSetProxy.getMaxScore()}
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                            </Toolbar>
                    </AppBar>
                </div>
                <div className={classes.acknowledgedBlock}>
                    <Grid container>
                        <Grid item xs={9} className={classes.summeryContainer}>
                            You are about to submit a total of {selectedMeasuresCount} measurements for the performance year of {yearInfo.year}.
                            <br /><br />
                            <SummaryGauges
                                items={measureSetProxies.filter(msp => msp.isIncluded() || msp.id === "review").map((msp) => ({
                                    label: msp.id === "review" ? "Total" : msp.title(),
                                    score: msp.getScore(),
                                    maxScore: msp.getMaxScore(),
                                    unweightedScore: msp.id === "review" ? undefined : msp.getScore(true),
                                    unweightedMaxScore:  msp.id === "review" ? undefined : msp.getMaxScore(true),
                                    selectedMeasuresCount: msp.getSelectedMeasuresCount(),
                                    bonusScore: msp.id === "review" ? msp.getBonusScore() : undefined
                                }))}
                            />
                            {messages.length > 0 && (
                                <div className={classes.messagesContainer}>
                                    {messages.map((msg, index) => (
                                        <div key={index}>
                                            <AlertBar
                                                severity={msg.type}
                                                label={msg.title}
                                                dense={"dense"}
                                                size={"small"}
                                            >
                                                {msg.details}
                                            </AlertBar>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={3} className={classes.submitContainer}>
                            {canSubmit ? (
                                <div>
                                    <label>
                                        <Checkbox
                                            dense="small"
                                            onChange={() => setAcknowledged(!acknowledged)}
                                            checked={acknowledged}
                                        />
                                        <br/>
                                        I acknowledge by clicking submit, my measurement data will be immediately submitted for scoring.
                                    </label>
                                    <br />
                                    <br />
                                    <br />
                                    <Button
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        disabled={!(acknowledged && canSubmit)}
                                        onClick={onSubmit}
                                        fullWidth={true}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    The submission window is not currently open.
                                    Please check back between {moment(yearInfo.submissionOpen).format(config.ui.dateFormat)} and {moment(yearInfo.submissionClose).format(config.ui.dateFormat)}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        </React.Fragment>
    );

};

export default Container(
    ReviewAndSubmit,
    () => [
        MeasuresMetaStore,
        MeasuresDraftStore,
        MeasuresScoreStore,
        PerformanceYearStore
    ],
    (state, props) => ({
        ...props,
        canSubmit: PerformanceYearStore.canSubmit()
    }),
    {pure: false}
);
